import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from "./store";
import { useAuthStore } from "@store/modules/authStore";
import { createI18n } from "vue-i18n";
import en from "@/i18n/en.json";
import es from "@/i18n/es.json";
import * as echarts from "echarts";
import wonderlandTheme from "@/assets/themes/wonderland";
import VueGtag from "vue-gtag-next";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "@/styles/variables.css";
import "@/styles/main.css";
import "element-plus/dist/index.css";

echarts.registerTheme("wonderland", wonderlandTheme);

const storedLocale = localStorage.getItem("user-locale") || "en";
const i18n = createI18n({
  locale: storedLocale,
  fallbackLocale: "en",
  messages: {
    en,
    es,
  },
});

// Make the language reference accessible
const currentLanguage = ref(i18n.global.locale);
export { currentLanguage };

const app = createApp(App)
  .use(ElementPlus)
  .use(router)
  .use(i18n)
  .use(pinia)
  .use(VueGtag, {
    property: {
      id: "G-17MY1F7V9P",
      params: {
        anonymize_ip: true,
        send_page_view: true,
        cookie_flags: "SameSite=None;Secure",
      },
    },
  });

// Register Element Plus icons globally
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

const authStore = useAuthStore();
authStore.restoreAuthState();

app.mount("#app");

// Prevent the ResizeObserver loop limit exceeded error
if (typeof window !== "undefined") {
  const observer = new ResizeObserver(() => {
    document.body.style.transform = "scale(1)";
  });
  observer.observe(document.body);
  window.addEventListener("error", (event) => {
    if (event.message.includes("ResizeObserver loop")) {
      event.stopImmediatePropagation();
    }
  });
  window.addEventListener("unload", () => observer.disconnect());
}

// Warning suppression for deprecated type.text on Element Plus components
console.warn = (msg) => {
  if (typeof msg === "string" && msg.includes("text is about to be deprecated")) {
    return;
  }
};
