export default [
  {
    title: "sidebar.mainDashboard",
    links: [
      { name: "sidebar.home", path: "/", icon: "home" },
      { name: "sidebar.clients", path: "/clients", icon: "clients" },
      { name: "sidebar.testResults", path: "/test-results", icon: "results" },
      { name: "sidebar.analytics", path: "/analytics", icon: "analytics" },
      { name: "sidebar.forecast", path: "/forecast", icon: "forecast" },
    ],
  },
  {
    title: "sidebar.resources",
    links: [
      { name: "sidebar.learningHub", path: "/learning-hub", icon: "learning" },
      { name: "sidebar.supportHub", path: "/support-hub", icon: "support" },
      { name: "sidebar.micronStore", path: "/micron-store", icon: "store" },
    ],
  },
  {
    title: "sidebar.accounts",
    links: [
      { name: "sidebar.account", path: "/account", icon: "account" },
      { name: "sidebar.settings", path: "/settings", icon: "settings" },
      { name: "sidebar.logout", path: "/logout", icon: "logout" },
    ],
  },
];
