<template>
  <div class="app-header">
    <div class="header-left">
      <div class="breadcrumb">{{ breadcrumbText }}</div>
      <h1 class="header-title">{{ title }}</h1>
    </div>
    <div class="header-right">
      <LanguageSwitcher class="language-switcher" />
      <div class="icon-container">
        <div class="user-avatar">
          <span class="user-initials">{{ userInitials }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { iconMap } from "@/assets/icons/iconMap";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher.vue";

export default defineComponent({
  name: "AppHeader",
  components: {
    LanguageSwitcher,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    breadcrumbText: {
      type: String,
      required: true,
    },
    userInitials: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      iconMap,
    };
  },
});
</script>

<style scoped>
.language-switcher {
  right: 20px;
  font-size: 16px;
}

.app-header {
  padding: 20px;
  background: white;
  border-bottom: 2px solid #f2f4f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  flex-direction: column;
}

.breadcrumb {
  color: #98a2b3;
  font-size: 14px;
  font-family: "Pulp Display", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.header-title {
  color: #344054;
  font-size: 28px;
  font-family: "Pulp Display", sans-serif;
  line-height: 44px;
  margin: 0;
  font-weight: bolder;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon-container {
  display: flex;
  gap: 24px;
  align-items: center;
}

.user-avatar {
  width: 52px;
  height: 52px;
  background: #f3fbfa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c2e6e4;
  flex-shrink: 0;
}

.user-initials {
  color: #00a394;
  font-size: 20px;
  font-family: "Pulp Display", sans-serif;
  font-weight: 400;
  line-height: 52px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 4px;
}

@media (max-width: 768px) {
  .icon-container {
    margin-top: 5px;
  }

  .user-avatar {
    width: 42px;
    height: 42px;
  }

  .user-initials {
    font-size: 16px;
    padding-top: 0px;
  }

  .language-switcher {
    font-size: 14px;
    right: 10px;
    top: 5px;
  }
}

@media (max-width: 480px) {
  .header-title {
    font-size: 28px;
  }

  .breadcrumb {
    font-size: 12px;
  }
  .icon-container {
    margin-top: 5px;
  }

  .user-avatar {
    width: 42px;
    height: 42px;
  }

  .user-initials {
    font-size: 16px;
    padding-top: 0px;
  }

  .language-switcher {
    font-size: 12px;
    right: 5px;
    top: 5px;
  }

  .app-header {
    padding: 10px;
    padding-bottom: 20px;
  }

  .header-right {
    gap: 0px;
  }
}
</style>
