<template>
  <router-link :to="to" :exact="exact" class="element" :class="{ active: isActive }">
    <div :class="iconClasses" :style="iconStyle"></div>
    <div v-show="!isCollapsed" class="label">
      {{ label }}
      <span v-show="badge !== null" class="badge">{{ badge }}</span>
    </div>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import { iconMap } from "@/assets/icons/iconMap";

export default defineComponent({
  name: "SidebarLink",
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    badge: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const route = useRoute();

    // Check if the current route matches the main or child routes
    const isActive = computed(() => {
      const currentPath = route.path;
      const targetPath = typeof props.to === "string" ? props.to : props.to.path;
      // Check if the current path matches the target or is a child route of the target
      return currentPath === targetPath || currentPath.startsWith(targetPath + "/");
    });

    const iconStyle = computed(() => {
      const iconPath = iconMap[props.icon];
      return {
        backgroundImage: `url(${iconPath})`,
        filter: isActive.value ? "var(--primary-filter)" : "var(--secondary-filter)", // Adjust filter dynamically
      };
    });

    const iconClasses = computed(() => ({
      icon: true,
      "icon-collapsed": props.isCollapsed,
    }));

    return {
      isActive,
      iconStyle,
      iconClasses,
    };
  },
});
</script>

<style scoped>
.element {
  position: relative;
  color: var(--secondary-color);
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition:
    background-size 0.3s,
    color 0.3s;
}

.element.active {
  color: var(--primary-color);
  background: rgba(0, 163, 148, 0.1);
  width: 100%;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  background-repeat: no-repeat;
  transition:
    width 0.3s,
    height 0.3s,
    background-size 0.3s,
    filter 0.3s;
  background-size: contain;
}

.icon-collapsed {
  height: 32px;
  margin: 0 auto;
}

.label {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.badge {
  background: var(--light-primary-color);
  border: 1px var(--soft-primary-color) solid;
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 700;
  color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  min-width: 18px;
  height: 18px;
}

.active::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary-color);
}

@media (max-width: 768px) {
  .label {
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .label {
    padding-right: 5px;
  }
}
</style>
