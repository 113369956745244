<template>
  <div v-cloak id="app">
    <div v-if="authDetermined" :class="containerClass">
      <SidebarComponent
        v-if="isAuthenticated && shouldShowSidebar"
        @sidebar-toggled="handleSidebarToggled"
        @sidebar-transitioned="handleSidebarTransitioned"
      />
      <div :class="mainContentClass">
        <AppHeader
          v-if="isAuthenticated && shouldShowSidebar"
          :title="pageTitle"
          :breadcrumb-text="breadcrumbText"
          :user-initials="userInitials"
        />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useAuthStore } from "@store/modules/authStore";
import { useUserStore } from "@/store/modules/userStore";
import SidebarComponent from "@components/layout/SidebarComponent.vue";
import AppHeader from "@/components/layout/AppHeader.vue";

export default defineComponent({
  name: "App",
  components: {
    SidebarComponent,
    AppHeader,
  },
  setup() {
    const route = useRoute();
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const authDetermined = ref(false);
    const isAuthenticated = computed(() => !!authStore.token);

    onMounted(() => {
      authDetermined.value = true;
    });

    const shouldShowSidebar = computed(() => {
      const noSidebarPaths = ["/login", "/forgot-password", "/Password"];
      return !noSidebarPaths.includes(route.path);
    });

    // Map route paths to title and breadcrumb
    const pageTitle = computed(() => {
      switch (route.name) {
        case "AddClient":
          return "Add Client";
        case "ClientDetails":
          return "Client Details";
        case "TestResults":
          return "Test Results";
        case "TestResultsDetails":
          return "Test Details";
        case "MicronStore":
          return "Micron Store";
        case "LearningHub":
          return "Learning Hub";
        case "SupportHub":
          return "Support Hub";
        case "NotFound":
          return "Page Not Found";
        default:
          return route.name ? String(route.name) : "Home";
      }
    });

    const breadcrumbText = computed(() => {
      switch (route.name) {
        case "Home":
          return "Main Dashboard / Home";
        case "Clients":
          return "Main Dashboard / Clients";
        case "TestResults":
          return "Main Dashboard / Test Results";
        case "TestResultsDetails":
          return "Main Dashboard / Test Results / Test Details";
        case "Analytics":
          return "Main Dashboard / Analytics";
        case "Forecast":
          return "Main Dashboard / Forecast";
        case "AddClient":
          return "Main Dashboard / Clients / Add Client";
        case "ClientDetails":
          return "Main Dashboard / Clients / Client Details";
        default:
          return "Main Dashboard";
      }
    });

    // Compute user initials from the user's name
    const userInitials = computed(() => {
      const name = userStore.name || "";
      const initials = name
        .split(" ")
        .map((part) => part.charAt(0).toUpperCase())
        .join("");
      return initials.slice(0, 2);
    });

    const containerClass = computed(() => ({
      "container-with-sidebar": shouldShowSidebar.value,
      "container-centered": !shouldShowSidebar.value,
    }));

    const mainContentClass = computed(() => ({
      "view-main-content": shouldShowSidebar.value,
      "centered-content": !shouldShowSidebar.value,
    }));

    return {
      authDetermined,
      isAuthenticated,
      shouldShowSidebar,
      containerClass,
      mainContentClass,
      pageTitle,
      breadcrumbText,
      userInitials,
    };
  },
  methods: {
    handleSidebarToggled() {
      console.log("Sidebar toggled");
    },
    handleSidebarTransitioned() {
      window.dispatchEvent(new Event("sidebar-toggled"));
    },
  },
});
</script>

<style scoped>
.app-layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.view-main-content {
  flex-grow: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
}
</style>
