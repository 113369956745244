import { fetchUserData, fetchBusinessList, fetchBusinessStats } from "@/services/apiService";

export default {
  async getUserData() {
    return await fetchUserData();
  },
  async getBusinessList() {
    return await fetchBusinessList();
  },
  async getBusinessStats(businessId: number) {
    return await fetchBusinessStats(businessId);
  },
};
