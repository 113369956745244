import { defineStore } from "pinia";
import UserRepository from "@/repositories/UserRepository";

export interface UserState {
  id: number;
  email: string;
  name: string;
  avatar: string;
  roles: {
    isStaff: boolean;
    isQc: boolean;
    isTester: boolean;
    isAdmin: boolean;
    isEndUser: boolean;
    isBusinessOwner: boolean;
  };
  businesses: Business[];
  selectedBusinessId: number | null;
  testsPrevious30Days: number;
  testsLast30Days: number;
  inactiveClients: number;
  loading: boolean;
}

interface Business {
  id: number;
  name: string;
  logo: string | null;
  credits: number;
  expirydate: string;
  role: number;
  infinite_credits: boolean;
  fluke_kit: boolean;
  flukekitnumber: string | null;
  activationdate: string;
}

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    id: 0,
    email: "",
    name: "",
    avatar: "",
    roles: {
      isStaff: false,
      isQc: false,
      isTester: false,
      isAdmin: false,
      isEndUser: true,
      isBusinessOwner: false,
    },
    businesses: [],
    selectedBusinessId: null,
    testsPrevious30Days: 0,
    testsLast30Days: 0,
    inactiveClients: 0,
    loading: true,
  }),
  actions: {
    async fetchUserData() {
      const data = await UserRepository.getUserData();
      this.id = data.id;
      this.email = data.email;
      this.name = data.name;
      this.avatar = data.avatar;
      this.roles = {
        isStaff: data.is_staff || false,
        isQc: data.is_qc || false,
        isTester: data.is_tester || false,
        isAdmin: data.is_admin || false,
        isBusinessOwner: data.is_business_owner || false,
        isEndUser: !data.is_staff && !data.is_admin && !data.is_qc && !data.is_tester,
      };
    },
    async fetchBusinessList() {
      const businesses = await UserRepository.getBusinessList();
      this.businesses = businesses;
      if (businesses.length > 0) {
        this.selectedBusinessId = businesses[0].id;
        await this.fetchBusinessData();
      }
    },
    async fetchBusinessData() {
      this.loading = true;
      if (!this.selectedBusinessId) return;

      try {
        // const data = await UserRepository.getBusinessStats(this.selectedBusinessId);
        // this.testsPrevious30Days = data.testsPrevious30Days;
        // this.testsLast30Days = data.testsLast30Days;
        // this.inactiveClients = data.inactiveClients;
        this.testsPrevious30Days = 70;
        this.testsLast30Days = 90;
        this.inactiveClients = 10;
      } finally {
        this.loading = false;
      }
    },
    selectBusiness(businessId: number) {
      this.selectedBusinessId = businessId;
      this.fetchBusinessData();
    },
  },
  getters: {
    selectedBusiness(): Business | undefined {
      return this.businesses.find((business) => business.id === this.selectedBusinessId);
    },
    welcomeMessage(): string {
      if (this.loading) {
        return "";
      }
      const businessName = this.selectedBusiness?.name || "Your business";
      const testsLast30Days = this.testsLast30Days;
      const inactiveClients = this.inactiveClients;
      const percentageChange =
        this.testsPrevious30Days === 0
          ? 0
          : ((testsLast30Days - this.testsPrevious30Days) / this.testsPrevious30Days) * 100;
      const absoluteChange = Math.abs(percentageChange).toFixed(1);
      const changeText = percentageChange > 0 ? "more" : "less";

      // Case 1: No tests this month but inactive clients exist
      if (testsLast30Days === 0 && inactiveClients > 0) {
        return `There are <span class="highlighted-value">${inactiveClients} clients</span> that have not had their animals tested in over 6 months, review your client list and contact them to run a FEC.`;
      }
      // Case 2: No tests and no inactive clients
      if (testsLast30Days === 0 && inactiveClients === 0) {
        return `There are no tests conducted this month, focus on increasing testing this month to gather more insights.`;
      }
      // Case 3: Restarting testing after inactivity
      if (this.testsPrevious30Days === 0 && testsLast30Days > 0) {
        return `${businessName} has started testing again with <span class="highlighted-value">${testsLast30Days} tests</span> this month. Great start! Keep it up to gather valuable insights.`;
      }
      // Case 4: Small changes or no change
      if (percentageChange > 0 && percentageChange <= 5) {
        return `${businessName} has conducted <span class="highlighted-value">${testsLast30Days} tests</span> in the last 30 days, maintaining a steady performance. Keep looking for opportunities to grow!`;
      }
      if (percentageChange === 0) {
        return `${businessName} has maintained the same testing level as last month. Consistency is key! Keep up the steady pace to ensure accurate insights over time.`;
      }
      // Case 5: Significant increase in testing activity
      if (percentageChange > 5 && percentageChange <= 300) {
        return `${businessName} has conducted <span class="highlighted-value">${testsLast30Days} tests</span> in the last 30 days, that's <span class="highlighted-value">${absoluteChange}% ${changeText}</span> than last month. Great job! Keep the momentum going!`;
      }
      if (percentageChange > 300) {
        return `${businessName} has conducted <span class="highlighted-value">${testsLast30Days} tests</span> in the last 30 days. Great job! Keep the momentum going!`;
      }
      // Case 6: Decrease in testing activity
      if (percentageChange < 0) {
        if (percentageChange < -30 && inactiveClients > 0) {
          return `There are <span class="highlighted-value">${inactiveClients} clients</span> that have not had their animals tested in over 6 months, review your client list and contact them to run a FEC.`;
        }
        return `${businessName} has conducted <span class="highlighted-value">${testsLast30Days} tests</span> in the last 30 days, that's <span class="highlighted-value">${absoluteChange}% ${changeText}</span> than last month. Focus on increasing testing this month to gather more insights.`;
      }
      // Default message when no other conditions are met.
      return "Welcome to the Micron Kit analytics platform, run some tests to start generating insights from the data.";
    },
    isRoleBasedView(): boolean {
      return this.roles.isStaff || this.roles.isAdmin;
    },
  },
});
