export const iconMap: { [key: string]: string } = {
  home: require("./home.svg"),
  bookmark: require("./bookmark.svg"),
  users: require("./users.svg"),
  results: require("./test-results-green.png"),
  total: require("./test-results.svg"),
  analysing: require("./analysing.svg"),
  positive: require("./positive.svg"),
  negative: require("./negative.svg"),
  account: require("./account.svg"),
  analytics: require("./analytics.svg"),
  forecast: require("./forecast.svg"),
  close: require("./close.svg"),
  filter: require("./filter.svg"),
  search: require("./search.svg"),
  star: require("./star.svg"),
  calendar: require("./calendar.svg"),
  ovine: require("./sheep.png"),
  bovine: require("./cow.png"),
  caprine: require("./goat.png"),
  equine: require("./horse.png"),
  "ovine-gif": require("./sheep_dancing.gif"),
  "bovine-gif": require("./cow_dancing.gif"),
  "caprine-gif": require("./goat_dancing.gif"),
  "equine-gif": require("./horse_dancing.gif"),
  eyes_open: require("./eyes_open.png"),
  eyes_closed: require("./eyes_closed.png"),
  alert: require("./alert-triangle.svg"),
  hourglass: require("./hourglass.svg"),
  refresh: require("./refresh.svg"),
  shield: require("./shield-tick.svg"),
  down_arrow: require("./down_arrow.svg"),
  up_arrow: require("./up_arrow.svg"),
  check: require("./check_icon.png"),
  english_flag: require("./english_flag.svg"),
  spanish_flag: require("./spanish_flag.png"),
  credits: require("./coins.svg"),
  clients: require("./clients.svg"),
  support: require("./support.svg"),
  settings: require("./settings.svg"),
  logout: require("./logout.svg"),
  store: require("./store.svg"),
  learning: require("./learning.svg"),
  upload: require("./import.svg"),
  download: require("./download.svg"),
  add: require("./add.svg"),
  list: require("./list.svg"),
  map: require("./map.svg"),
  lightbulb: require("./lightbulb.svg"),
  clipboard: require("./clipboard.svg"),
  orders: require("./orders.svg"),
  packages: require("./package.svg"),
};
