import { createRouter, createWebHashHistory, RouteRecordRaw, RouteLocationNormalized } from "vue-router";
import { useAuthStore } from "@store/modules/authStore";
import { useGtag } from "vue-gtag-next";
import i18n from "@/i18n";
import { watch } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "home.title",
    },
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("@views/Clients.vue"),
    meta: {
      requiresAuth: true,
      title: "clients.title",
    },
  },
  {
    path: "/clients/add",
    name: "AddClient",
    component: () => import("@views/AddClient.vue"),
    meta: {
      requiresAuth: true,
      title: "addClient.title",
    },
  },
  {
    path: "/clients/details/:id",
    name: "ClientDetails",
    component: () => import("@views/ClientDetails.vue"),
    meta: {
      requiresAuth: true,
      title: "clientDetails.title",
    },
    props: true,
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: () => import("@views/Analytics.vue"),
    meta: {
      requiresAuth: true,
      title: "analytics.title",
    },
  },
  {
    path: "/forecast",
    name: "Forecast",
    component: () => import("@views/Forecast.vue"),
    meta: {
      requiresAuth: true,
      title: "forecast.title",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@views/Login.vue"),
    meta: {
      title: "login.title",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@views/ForgotPassword.vue"),
    meta: {
      title: "forgotPassword.title",
    },
  },
  {
    path: "/Password/Recover/Change/:token",
    name: "PasswordRecover",
    component: () => import("@/views/PasswordRecover.vue"),
    meta: {
      title: "passwordRecover.title",
    },
    props: true,
  },
  {
    path: "/test-results",
    name: "TestResults",
    component: () => import("@views/TestResults.vue"),
    meta: {
      title: "testResults.title",
    },
  },
  {
    path: "/test-results/details/:id/:type?",
    name: "TestResultsDetails",
    component: () => import("@views/TestResultsDetails.vue"),
    meta: {
      requiresAuth: true,
      title: "testResultsDetails.title",
    },
    props: true,
  },
  {
    path: "/micron-store",
    name: "MicronStore",
    // component: () => import("@/views/Store.vue"),
    component: () => import("@views/ComingSoon.vue"),
    meta: {
      title: "comingSoon.title",
    },
  },
  {
    path: "/learning-hub",
    name: "LearningHub",
    component: () => import("@views/ComingSoon.vue"),
    meta: {
      title: "comingSoon.title",
    },
  },
  {
    path: "/support-hub",
    name: "SupportHub",
    component: () => import("@views/ComingSoon.vue"),
    meta: {
      title: "comingSoon.title",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@views/ComingSoon.vue"),
    meta: {
      title: "comingSoon.title",
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@views/ComingSoon.vue"),
    meta: {
      title: "comingSoon.title",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@views/NotFound.vue"),
    meta: {
      title: "NotFound.title",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const updateDocumentTitle = (to: RouteLocationNormalized) => {
  const defaultTitle = "Micron Kit";
  const routeName = String(to.name || "NotFound");
  const titleKey = `routes.${routeName}`;
  const i18nTitle = `${i18n.global.t(titleKey)} | ${defaultTitle}`;
  document.title = i18nTitle;
};

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.token) {
    next("/login");
  } else if (to.path === "/login" && authStore.token) {
    next("/");
  } else {
    updateDocumentTitle(to);
    next();
  }
});

watch(
  () => i18n.global.locale.value,
  () => {
    updateDocumentTitle(router.currentRoute.value);
  },
  { immediate: true },
);

const gtag = useGtag();
router.afterEach((to) => {
  gtag.event("page_view", {
    page_path: to.fullPath,
    page_title: to.name,
  });
});

export default router;
