import { UserState } from "@/store/modules/userStore";

export const rolePermissions: Record<string, (keyof UserState["roles"])[]> = {
  home: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
  clients: ["isStaff", "isAdmin", "isEndUser"],
  testresults: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
  analytics: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
  forecast: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
  micronstore: ["isAdmin", "isStaff", "isEndUser"],
  learninghub: ["isAdmin", "isStaff", "isEndUser"],
  supporthub: ["isStaff", "isAdmin", "isEndUser"],
  settings: ["isStaff", "isQc", "isAdmin", "isEndUser"],
  account: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
  logout: ["isStaff", "isQc", "isTester", "isAdmin", "isEndUser"],
};
