import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-601750b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selector-container" }
const _hoisted_2 = { class: "flag" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "language-label" }
const _hoisted_5 = { class: "dropdown-icon" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "dropdown-menu"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "flag" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "language-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "language-switcher",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.getFlagImage(_ctx.currentLanguage),
          alt: "Flag",
          class: "flag-image"
        }, null, 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.currentLanguage === "en" ? "English" : "Español"), 1),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: _ctx.iconMap['down_arrow'],
          alt: "Dropdown Arrow",
          class: _normalizeClass(["arrow-icon", { 'arrow-up': _ctx.isDropdownOpen }])
        }, null, 10, _hoisted_6)
      ])
    ]),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lang) => {
            return (_openBlock(), _createElementBlock("div", {
              key: lang,
              class: "dropdown-item",
              onClick: _withModifiers(($event: any) => (_ctx.selectLanguage(lang)), ["stop"])
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: _ctx.getFlagImage(lang),
                  alt: "Flag",
                  class: "flag-image"
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("div", _hoisted_11, _toDisplayString(lang === "en" ? "English" : "Español"), 1)
            ], 8, _hoisted_8))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}