import { createI18n } from "vue-i18n";
import en from "./en.json";
import es from "./es.json";

const messages = {
  en,
  es,
};

// Function to detect browser language or use stored language in localStorage
const getBrowserLanguage = () => {
  const storedLang = localStorage.getItem("app_language");
  if (storedLang) {
    return storedLang;
  }

  const browserLang = navigator.language.split("-")[0];
  return Object.keys(messages).includes(browserLang) ? browserLang : "en";
};

// Create i18n instance with dynamic language detection
const i18n = createI18n({
  legacy: false,
  locale: getBrowserLanguage(),
  fallbackLocale: "en",
  messages,
});

export default i18n;
