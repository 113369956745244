import { defineStore } from "pinia";
import { login, requestPasswordReset, resetPassword } from "@/services/apiService";
import { useUserStore } from "./userStore";
import axios from "axios";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: localStorage.getItem("authToken") || "",
  }),
  actions: {
    async login(credentials: { email: string; password: string }) {
      try {
        const response = await login({
          email: credentials.email,
          password: credentials.password,
        });
        this.token = response.token;
        localStorage.setItem("authToken", this.token); // Persist the token in localStorage
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;

        const userStore = useUserStore();
        await userStore.fetchUserData(); // Fetch user data after login
        await userStore.fetchBusinessList(); // Fetch business list after login
      } catch (error) {
        console.error("Error during login:", error);
        throw error; // Re-throw error for the caller to handle
      }
    },
    logout() {
      this.token = "";
      localStorage.removeItem("authToken");
      axios.defaults.headers.common["Authorization"] = "";
      const userStore = useUserStore();
      userStore.$reset(); // Reset user store on logout
    },
    async restoreAuthState() {
      if (this.token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
        const userStore = useUserStore();
        await userStore.fetchUserData(); // Fetch user data on page reload
        await userStore.fetchBusinessList(); // Fetch business list after login
      }
    },
    async requestPasswordReset(email: string) {
      try {
        await requestPasswordReset({ email: email });
      } catch (error) {
        throw new Error("Failed to send password reset email");
      }
    },
    async resetPassword(data: { token: string; newPassword: string }) {
      try {
        await resetPassword(data);
      } catch (error) {
        throw new Error("Failed to reset password");
      }
    },
  },
});
