import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-537da94e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-header" }
const _hoisted_2 = { class: "header-left" }
const _hoisted_3 = { class: "breadcrumb" }
const _hoisted_4 = { class: "header-title" }
const _hoisted_5 = { class: "header-right" }
const _hoisted_6 = { class: "icon-container" }
const _hoisted_7 = { class: "user-avatar" }
const _hoisted_8 = { class: "user-initials" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageSwitcher = _resolveComponent("LanguageSwitcher")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.breadcrumbText), 1),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_LanguageSwitcher, { class: "language-switcher" }),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.userInitials), 1)
        ])
      ])
    ])
  ]))
}