<template>
  <div v-show="!isCollapsed" class="section">{{ title }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SidebarSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style scoped>
.section {
  margin: 20px 0 10px;
  color: var(--medium-gray);
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-left: 22px;
}
</style>
