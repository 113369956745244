import { defineStore } from "pinia";
import { ref } from "vue";

export const useLanguageStore = defineStore("language", () => {
  const language = ref(localStorage.getItem("user-locale") || "en");

  const setLanguage = (lang: string) => {
    language.value = lang;
    localStorage.setItem("user-locale", lang);
  };

  return {
    language,
    setLanguage,
  };
});
