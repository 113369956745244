<template>
  <aside :class="sidebarClass" @transitionend="handleTransitionEnd">
    <div class="content">
      <div class="logo" @click="toggleSidebar">
        <img class="logo-image" src="@/assets/images/micron_logo.svg" alt="Micron Agritech" />
        <div v-show="!isCollapsed" class="logo-text">Micron Agritech</div>
      </div>
      <div v-show="isCollapsed" class="toggle-button-wrapper">
        <button class="toggle-button" @click="toggleSidebar">☰</button>
      </div>
      <nav class="navigation">
        <div v-for="(section, sectionIndex) in filteredSections" :key="sectionIndex">
          <SidebarSection :title="$t(section.title)" :is-collapsed="isCollapsed" />
          <SidebarLink
            v-for="(link, linkIndex) in section.links"
            :key="linkIndex"
            :to="link.path"
            :icon="link.icon"
            :label="$t(link.name)"
            :is-collapsed="isCollapsed"
            :badge="link.badge ?? undefined"
            @click="handleLinkClick(link)"
          />
        </div>
      </nav>
    </div>
  </aside>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import { useGtag } from "vue-gtag-next";
import SidebarSection from "@components/layout/SidebarSection.vue";
import SidebarLink from "@components/layout/SidebarLink.vue";
import sidebarConfig from "@/config/sidebarConfig";
import { fetchBadgeData, BadgeData } from "@/services/apiService";
import { useAuthStore } from "@store/modules/authStore";
import { useUserStore, UserState } from "@store/modules/userStore";
import { rolePermissions } from "@/config/rolePermissions";
import { useRouter } from "vue-router";
import type {
  SidebarSection as SidebarSectionType,
  SidebarLink as SidebarLinkType,
} from "@/config/sidebarConfig";

export default defineComponent({
  name: "SidebarComponent",
  components: { SidebarSection, SidebarLink },
  emits: ["sidebar-toggled", "sidebar-transitioned"],
  setup(props, { emit }) {
    const gtag = useGtag();
    const authStore = useAuthStore();
    const userStore = useUserStore();
    const router = useRouter();
    const isCollapsed = ref(false);

    const filteredSections = computed(() => {
      return sections.value.map((section) => ({
        title: section.title,
        links: section.links.filter((link) => {
          const linkName = link.name
            .replace(/\s+/g, "")
            .replace(/sidebar./g, "")
            .toLowerCase() as keyof typeof rolePermissions;
          const linkPermissions = rolePermissions[linkName];

          const hasPermission = linkPermissions
            ? linkPermissions.some((role: keyof UserState["roles"]) => {
                return userStore.roles[role];
              })
            : true;

          return hasPermission;
        }),
      }));
    });

    const sections = ref<SidebarSectionType[]>(JSON.parse(JSON.stringify(sidebarConfig)));

    const sidebarClass = computed(() => ({
      sidebar: true,
      "sidebar-collapsed": isCollapsed.value,
      "sidebar-expanded": !isCollapsed.value,
    }));

    const handleLinkClick = (link: SidebarLinkType) => {
      if (link.name === "sidebar.logout") {
        authStore.logout();
        router.push("/login");
      } else {
        gtag.event("navigation_click", {
          event_category: "navigation",
          event_label: link.path,
        });
      }
    };

    const updateBadges = async () => {
      const badgeData: BadgeData = await fetchBadgeData();
      sections.value.forEach((section) => {
        section.links.forEach((link: SidebarLinkType) => {
          // Generate key for badge data based on link name without spaces and prefix
          const badgeKey = link.name
            .toLowerCase()
            .replace(/ /g, "")
            .replace(/sidebar./g, "");
          link.badge = badgeData[badgeKey] ?? null;
        });
      });
    };

    const toggleSidebar = () => {
      isCollapsed.value = !isCollapsed.value;
      emit("sidebar-toggled");
    };

    const checkWindowSize = () => {
      isCollapsed.value = window.innerWidth < 605;
    };

    const handleTransitionEnd = () => {
      emit("sidebar-transitioned");
    };

    onMounted(() => {
      window.addEventListener("resize", checkWindowSize);
      checkWindowSize();
      updateBadges();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", checkWindowSize);
    });

    return {
      isCollapsed,
      toggleSidebar,
      filteredSections,
      handleLinkClick,
      sidebarClass,
      handleTransitionEnd,
    };
  },
});
</script>

<style scoped>
@import "@/styles/variables.css";

.sidebar {
  background-color: white;
  margin: 22px 0;
  padding: 22px 0;
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 100vh;
}

.sidebar-collapsed {
  width: 60px;
}

.logo-image {
  cursor: pointer;
  padding-bottom: 5px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  padding-left: 22px;
}

.toggle-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.toggle-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.navigation {
  margin: 40px 0 10px;
  display: flex;
  flex-direction: column;
}

.logo-text {
  color: var(--primary-color);
  font-size: 20px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 28px;
  margin-left: 10px;
}

.content {
  position: relative;
}

nav {
  display: flex;
  flex-direction: column;
}

nav a {
  padding: 10px;
  padding-left: 20px;
  color: var(--dark-gray);
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

nav a.router-link-exact-active {
  color: var(--primary-color);
  background: rgba(0, 163, 148, 0.1);
}

.section {
  margin: 20px 0 10px;
  color: var(--medium-gray);
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  padding-left: 22px;
}

.element {
  position: relative;
  color: var(--secondary-color);
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.element.active {
  background: rgba(0, 163, 148, 0.1);
  width: 100%;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  background-repeat: no-repeat;
  transition:
    width 0.3s,
    height 0.3s,
    background-size 0.3s;
  background-size: contain;
}

.icon-collapsed {
  height: 32px;
  margin: 0 auto;
}

.label {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.badge {
  background: var(--light-primary-color);
  border: 1px var(--soft-primary-color) solid;
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 700;
  color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 10px;
  min-width: 18px;
  height: 18px;
}

.active::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary-color);
}
</style>
