<template>
  <div class="language-switcher" @click="toggleDropdown">
    <div class="selector-container">
      <div class="flag">
        <img :src="getFlagImage(currentLanguage)" alt="Flag" class="flag-image" />
      </div>
      <div class="language-label">{{ currentLanguage === "en" ? "English" : "Español" }}</div>
      <div class="dropdown-icon">
        <img
          :src="iconMap['down_arrow']"
          alt="Dropdown Arrow"
          class="arrow-icon"
          :class="{ 'arrow-up': isDropdownOpen }"
        />
      </div>
    </div>
    <div v-if="isDropdownOpen" class="dropdown-menu">
      <div v-for="lang in languages" :key="lang" class="dropdown-item" @click.stop="selectLanguage(lang)">
        <div class="flag">
          <img :src="getFlagImage(lang)" alt="Flag" class="flag-image" />
        </div>
        <div class="language-label">{{ lang === "en" ? "English" : "Español" }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { iconMap } from "@/assets/icons/iconMap";
import { useLanguageStore } from "@/store/modules/languageStore";

export default defineComponent({
  name: "LanguageSwitcher",
  setup() {
    const { locale } = useI18n();
    const languageStore = useLanguageStore();
    const isDropdownOpen = ref(false);
    const languages: Array<"en" | "es"> = ["en", "es"];

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const selectLanguage = (lang: "en" | "es") => {
      locale.value = lang;
      languageStore.setLanguage(lang);
      isDropdownOpen.value = false;
    };

    const currentLanguage = ref(languageStore.language);
    watch(
      () => languageStore.language,
      (newLang) => {
        currentLanguage.value = newLang;
        locale.value = newLang;
      },
      { immediate: true },
    );

    const getFlagImage = (lang: string) => {
      return lang === "en" ? iconMap["english_flag"] : iconMap["spanish_flag"];
    };

    return {
      languages,
      isDropdownOpen,
      toggleDropdown,
      selectLanguage,
      getFlagImage,
      currentLanguage,
      iconMap,
    };
  },
});
</script>

<style scoped>
:root {
  --soft-primary-color: #c2e6e4;
  --primary-color: #00796b;
}

.language-switcher {
  width: auto;
  padding: 10px;
  background: white;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  display: inline-flex;
  align-items: center;
  z-index: 1000;
  position: relative;
  cursor: pointer;
}

.selector-container {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.flag {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.language-label {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #1d2939;
  transition: color 0.2s ease;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  transform: rotate(0deg);
}

.arrow-icon.arrow-up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 8px;
  z-index: 10;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    color 0.2s ease;
}

.dropdown-item:hover {
  background-color: rgba(194, 230, 228, 0.1);
}

.dropdown-item:hover .language-label {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .flag {
    width: 24px;
    height: 24px;
  }

  .language-label {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .flag {
    width: 24px;
    height: 24px;
  }

  .language-label {
    font-size: 10px;
  }

  .language-switcher {
    padding: 6px;
  }
}
</style>
